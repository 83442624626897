import "core-js/stable";
import "regenerator-runtime/runtime";
import EnvRoutesService from "./routes";
import axios from "axios";

// console.log(documen)
/*
 * This code is to test the document referrer and build out the logo switching
 */

// Object.defineProperty(document, 'referrer', {
// 	get: function() {
// 		return 'dhl.limblecmms.com';
// 	}
// });
// console.log(window.location.href);

const MOBILE_REDIRECT_ROUTE = "/mobileDashboard";

const isESignatureVerification = window.location.pathname === "/esignature-verification";

document.getElementById("loginBtn").addEventListener("click", function () {
   submitLogin();
});

document.getElementById("loginForm").addEventListener(
   "keydown",
   function (event) {
      if (event.key == "Enter") {
         submitLogin();
      }
   },
   false
);

document.getElementById("forgotPwBtn").addEventListener("click", function () {
   forgotMyPassword();
});
/*

   On page load we should check for the exsitance of a cookie and redirect the user if they have one? Maybe.

*/

let userField = "system_processor"; //these names are specifically misleading
let passField = "limble_region"; //these names are specifically misleading

let creds = getLoginLocalStorage(userField, passField);
if (creds.user && creds.pass) {
   document.getElementById("username").value = creds.user;
   document.getElementById("password").value = creds.pass;
   document.getElementById("box3").checked = true;
}

function submitLogin() {
   //IN THE FUTURE BEFORE WE POST TO THE LOGIN SERVER WILL NEED TO POST TO THE AUTH SERVER LOCATED IN N.CALI
   //THAT AUTH SERVER WILL HAVE TO RETURN WHICH REGION THE ACCOUNT IS IN BASED ON A HASH DATABASE/Dynamo
   // grecaptcha.ready(function () {
   //    grecaptcha
   //       .execute("6LeyXOAUAAAAAIEfLKdFbTscOgqtl34_M1cSWK09", { action: "login" })
   //       .then(function (token) {

   if (document.getElementById("username").value && document.getElementById("password").value) {
      login();
   } else {
      document.getElementById("loginErr").style.display = "";
      document.getElementById("loading").style.display = "none";
      setAllLogoDisplays("");
      return;
   }
   // });
   // });
}

function setAllLogoDisplays(displayCode = "") {
   var logos = document.getElementsByClassName("logo");
   for (var i = 0; i < logos.length; ++i) {
      var item = logos[i];
      item.style.display = displayCode;
   }
}

function login() {
   document.getElementById("loading").style.display = "flex";
   setAllLogoDisplays("none");
   document.getElementById("loginErr").style.display = "none";

   return EnvRoutesService.getRoutes().then((routes) => {
      //post to N.cali auth server to find out which region
      let config = {
         headers: { "Access-Control-Allow-Origin": routes.backendAuthServer },
         withCredentials: true,
      };
      axios
         .post(
            routes.backendAuthServer + "/login",
            {
               username: document.getElementById("username").value,
               password: document.getElementById("password").value,
               isMobile: checkMobile(),
            },
            config
         )
         .then(function (response) {
            if (response.data.success) {
               // console.log(response.data);
               setCookie("languageCookie", getCookie("languageCookie"), 365);
               //save pass info here.
               const box3 = document.getElementById("box3");
               if (box3 && box3.checked) {
                  setLoginLocalStorage(
                     document.getElementById("username").value,
                     document.getElementById("password").value
                  );
               } else {
                  clearLoginLocalStorage(userField, passField);
               }

               deleteUnusedCookies(response.data.link);

               /*
                  The server is going to have to return the URL they should be directed to..... Only it can know what creds each user has...
               */
               //if there is a document.referrer then they came here un-authenticated from an app subdomain so save that and once logged in shoot them over to where they were trying to go
               //catch if there is a document referrer. This is for white label customers. If they are a white label customer their subdomain will point to the app server, if they are not logged in they will be forwarded to auth.limblecmms.com where the logo will get replaced based on document referrer, and this will send them to their sub-domain app server
               // if (
               //    document.referrer.includes(".limblecmms.com") &&
               //    !document.referrer.includes("https://limblecmms.com") &&
               //    !document.referrer.includes("https://www.limblecmms.com")
               // ) {
               //This needs to be a bit smarter for white label customers and direct them to the page they have access to, but also the white label domain. Find a way to do this without string replacing... hopefully.
               //    window.location.href = document.referrer;
               // } else {
               if (isESignatureVerification) {
                  window.sessionStorage.removeItem("loginAttemptNumber");
                  window.parent.postMessage(
                     {
                        success: true,
                        user: response.data.user,
                     },
                     routes.appUrl
                  );
               } else {
                  const redirectUrl = evaluateRedirectUrl(response.data.link);
                  navigateToApp(redirectUrl);
               }
               // }
               //if remember me was checked then store their username and password in a cookie
            } else if (response.data.success == false && response.data.pwExpired == true) {
               document.getElementById("loading").style.display = "none";
               //display forgot my password modal with a message telling the user their password expired and will have to reset it via email
               openModal();
               document.getElementById("pwExpired").style.display = "";
            } else {
               //display message saying login failed
               setAllLogoDisplays("none");
               if (!isESignatureVerification) {
                  document.getElementById("errorContainer").classList.add("p-b-32");
               }

               document.getElementById("loginErr").style.display = "";
            }
            // console.log(response, "response from auth server");
         })
         .catch(function (error) {
            setAllLogoDisplays();

            if (!isESignatureVerification) {
               document.getElementById("errorContainer").classList.add("p-b-32");
            }
            document.getElementById("loginErr").style.display = "";
            document.getElementById("loading").style.display = "none";

            if (document.getElementById("ssoProviderErr")) {
               document.getElementById("ssoProviderErr").style.display = "block";
            }
            
            // console.log("CATCH ERROR");
            console.log(error);
               
            if (isESignatureVerification) {
               let loginAttemptNumber = window.sessionStorage.getItem("loginAttemptNumber") ?? 0;

               if (loginAttemptNumber >= 3) {
                  window.sessionStorage.removeItem("loginAttemptNumber");
                  window.parent.postMessage(
                     {
                        success: false,
                        user: 0
                     },
                     routes.appUrl
                  );
                  return;
               }

               window.sessionStorage.setItem("loginAttemptNumber", ++loginAttemptNumber);
               setElementAttribute("loginAttemptNumber", "innerHTML", loginAttemptNumber);
            }
         });
      //get the region return and post to that region's auth server
   });
}

// document.getElementById("region").addEventListener("click", function () {
// 	console.log("Click");
// });
//forgot my password button clicked here.
function enableForgotPassword() { 
   document.getElementById("forgotPwBtn").disabled = false;
}
window.enableForgotPassword = enableForgotPassword;

function forgotMyPassword() {
   document.getElementById("forgotPasswordSuccess").style.display = "none";
   if (document.getElementById("forgotMyPasswordUsername").value) {

      return EnvRoutesService.getRoutes().then((routes) => {
         setAllLogoDisplays("none");
         //post to N.cali auth server to find out which region
         var config = {
            headers: { "Access-Control-Allow-Origin": routes.backendAuthServer },
            withCredentials: true,
         };
         axios
            .post(
               routes.backendAuthServer + "/forgotMyPassword",
               {
                  username: document.getElementById("forgotMyPasswordUsername").value,
                  hcaptcha_client_response: hcaptcha.getResponse()
               },
               config
            )
            .then(function (response) {
               setAllLogoDisplays();
               document.getElementById("forgotPasswordErrorHandlingStyles").classList.add("p-b-32");
               if (response.data.success) {
                  document.getElementById("forgotPasswordSuccess").style.display = "inline";
               } else {
                  document.getElementById("forgotPasswordSuccess").style.display = "inline";
                  document.getElementById("loginErr").style.display = "none";
               }
               // console.log(response, "response from auth server");

               if (isESignatureVerification) {
                  window.parent.postMessage(
                     {
                        success: null,
                        user: 0
                     },
                     routes.appUrl
                  )
               }
            })
            .catch(function (error) {
               document.getElementById("forgotPasswordErrorHandlingStyles").classList.add("p-b-32");
               document.getElementById("loginErr").style.display = "";
               console.log(error);
            });
         //get the region return and post to that region's auth server
      });
   } else {
      return;
   }
}
/*

WHITE LABEL CUSTOMERS BASED ON REFERRER.... They will be forwarded to auth.limblecmms.com if they go do their sub-domain without an auth cookie

IT IS VERY IMPORTANT THAT ALL WHITE LABEL CUSTOMERS ARE LISTED HERE AND HAVE THEIR LOGO IN

*/
//White label logo imports
import limble from "url:../logos/LimbleLogo.svg";
import DHLLogo from "url:../logos/DHL.png";
import neutron from "url:../logos/neutron-light-background.png";
import enco from "url:../logos/enco.png";
import deliverEZ from "url:../logos/Deliverez_Logo_New_NoBG.png";
import epicGames from "url:../logos/epicGames.png";
import iotPlus from "url:../logos/iotplus-light-background.png";
import invata from "url:../logos/invata-light-background.png";
import netswat from "url:../logos/netswat.png";
import demirer from "url:../logos/demirer-hodling.png";
import scandinaviandrone from "url:../logos/Scandinavian-Drone-Logo-svart.png";
import ComstockFS from "url:../logos/2D-Comstock-Logo.jpg";
import harriganLogo from "url:../logos/HarriganLogo.png";
import sentegrityLogo from "url:../logos/sentegrity.png";
import dartbrookLogo from "url:../logos/Dartbrook_logo.svg";
import redaptiveLogo from "url:../logos/redaptive_logo.svg";
import swoosh from "url:../icons/LoginScreenSwoosh.svg";
import johndeereLogo from "url:../logos/johndeere_logo.png";
import KULogo from "url:../logos/KU.svg";
import homeFixLogo from "url:../logos/homeFixLogo.svg";
import intelsat from "url:../logos/intelsat.svg";
import jbm from "url:../logos/jbm.svg";
import access from "url:../logos/access.svg";

let src = limble;

function checkWhiteLabelDomainMatch(domain) {
   if (document.referrer.includes(domain)) {
      return true;
   } else if (getCookie("whiteLabelSubDomain").includes(domain)) {
      return true;
   } else {
      return false;
   }
}

if (checkWhiteLabelDomainMatch("dhl.limblecmms.com")) {
   src = DHLLogo;
} else if (checkWhiteLabelDomainMatch("neutron.limblecmms.com")) {
   src = neutron;
} else if (checkWhiteLabelDomainMatch("enco.limblecmms.com")) {
   src = enco;
} else if (checkWhiteLabelDomainMatch("deliver-ez.limblecmms.com")) {
   src = deliverEZ;
} else if (checkWhiteLabelDomainMatch("epic.limblecmms.com")) {
   src = epicGames;
} else if (checkWhiteLabelDomainMatch("iotplus.limblecmms.com")) {
   src = iotPlus;
} else if (checkWhiteLabelDomainMatch("invata.limblecmms.com")) {
   src = invata;
} else if (checkWhiteLabelDomainMatch("netswat.limblecmms.com")) {
   src = netswat;
} else if (checkWhiteLabelDomainMatch("demirer.limblecmms.com")) {
   src = demirer;
} else if (checkWhiteLabelDomainMatch("scandinaviandrone.limblecmms.com")) {
   src = scandinaviandrone;
} else if (checkWhiteLabelDomainMatch("comstockfs.limblecmms.com")) {
   src = ComstockFS;
} else if (checkWhiteLabelDomainMatch("harrigan.limblecmms.com")) {
   src = harriganLogo;
} else if (checkWhiteLabelDomainMatch("sentegrity.limblecmms.com")) {
   src = sentegrityLogo;
} else if (checkWhiteLabelDomainMatch("dartbrook.limblecmms.com")) {
   src = dartbrookLogo;
} else if (checkWhiteLabelDomainMatch("redaptive.limblecmms.com")) {
   src = redaptiveLogo;
} else if (checkWhiteLabelDomainMatch("johndeere.limblecmms.com")) {
   src = johndeereLogo;
} else if (checkWhiteLabelDomainMatch("kettering.limblecmms.com")) {
   src = KULogo;
} else if (checkWhiteLabelDomainMatch("homefix.limblecmms.com")) {
   src = homeFixLogo;
} else if (checkWhiteLabelDomainMatch("intelsat.limblecmms.com")) {
   src = intelsat;
} else if (checkWhiteLabelDomainMatch("jbmpackaging.limblecmms.com")) {
   src = jbm;
} else if (checkWhiteLabelDomainMatch("access.limblecmms.com")) {
   src = access;
}


var logos = document.getElementsByClassName("logo");
for (var i = 0; i < logos.length; ++i) {
   var item = logos[i];
   item.src = src;
}

/*

END OF WHITE LABEL CUSTOMER SECTION

*/

//if remember me is checked then store creds inside of local storage? or maybe not just use secure cookie?

//read the customer's lang cookie and set the front end to foriegn language

/*
   Language Setting
*/
//default setting is english
//Talk to nathan about this maybe? Lang files are pretty big maybe it's better to not load them all on page load

//if not lang cookie then default to english first
// setCookie("languageCookie", "english", 365);
//else read lang cookie and select that language first.
// console.log(getCookie('languageCookie'));
if (!getCookie("languageCookie")) {
   setCookie("languageCookie", "english", 365);
   loadLang("english");
} else {
   let lang = getCookie("languageCookie");
   loadLang(lang);
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const error = urlParams.get("error");
const idp = urlParams.get("idp");

if (idp == "true" && getCookie("ssoUsername")) {
   determineSSOProvider(getCookie("ssoUsername")).then(function (answer) {
      window.location.href = answer.data.ssoUrl;
   });
}

document.getElementById("ssoProviderErr").innerHTML = error;

function setFrontEnd() {
   // Due to time constraints, some phrases in esignature verification will not be translated
   if (!isESignatureVerification) {
      setElementAttribute("loginErr", "innerHTML", document.lang.badLogin);
      setElementAttribute("loginBtn", "innerHTML", document.lang.login);
      setElementAttribute("email-login-label", "innerHTML", document.lang.email + "/" + document.lang.username);
   }

   const loginAttemptNumber = window.sessionStorage.getItem("loginAttemptNumber");
   if (isESignatureVerification && loginAttemptNumber > 0) {
      document.getElementById("loginErr").style.display = "";
      setElementAttribute("loginAttemptNumber", "innerHTML", loginAttemptNumber);
   }
   
   setElementAttribute("slogan", "innerHTML", document.lang.EmpowerSlogan);
   setElementAttribute("empowerImage", "src", swoosh);
   setElementAttribute("forgotMyPasswordBtn", "innerHTML", document.lang.forgotPassword);
   setElementAttribute("rememberMe", "innerHTML", document.lang.rememberMe);
   setElementAttribute("dontHaveAnAccount", "innerHTML", document.lang.DoesYourCompanyNotHaveAnAccount);
   setElementAttribute("password-login-label", "innerHTML", document.lang.password);
   setElementAttribute("forgotMyPasswordUsernameLabel", "innerHTML", document.lang.email + "/" + document.lang.username);
   setElementAttribute("forgotPwBtn", "innerHTML", document.lang.ResetPassword);
   setElementAttribute("forgotPasswordInstruction", "innerHTML", document.lang.PleaseEnterYourUsernameOrEmailThenClickResetPassword);

   document.getElementById("sso").addEventListener("click", enterSSOUsernameManually);
   document.getElementById("forgotMyPasswordBtn").addEventListener("click", forgotPasswordClicked);
   document.getElementById("forgot-back-button").addEventListener("click", backButtonClicked);
   document.getElementById("sso-back-button").addEventListener("click", backButtonClicked);

   const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
   });
   if (params.autofill === 'true') {
      setElementAttribute("username", "value", params.username);
      setElementAttribute("ssoUsername", "value", params.userEmail);
   }
}

function setElementAttribute(elementId, attribute, value) {
   const element = document.getElementById(elementId);
   if (element) {
      element[attribute] = value;
   }
}

function determineSSOProvider(username) {

   return EnvRoutesService.getRoutes().then((routes) => {
      var config = {
         headers: { "Access-Control-Allow-Origin": routes.backendAuthServer },
         withCredentials: true,
      };
      return axios
         .post(
            routes.backendAuthServer + "/checkProvider",
            {
               username,
               isMobile: checkMobile(),
            },
            config
         )
         .then(function (answer) {
            return answer;
         });
   });
}

function verifyESignatureVerificationToken() {
   return EnvRoutesService.getRoutes().then((routes) => {
      var config = {
         headers: { "Access-Control-Allow-Origin": routes.backendAuthServer },
         withCredentials: true,
      };
      return axios
         .post(
            routes.backendAuthServer + "/verifyESignatureVerificationToken",
            {},
            config
         )
         .then(function (answer) {
            return answer;
         });
   });
}

function forgotPasswordClicked() {
   document.getElementById("loginForm").style.display = "none";
   document.getElementById("manualForgotPassword").style.display = "block";

   if (isESignatureVerification) {
      document.getElementById("login-btn-container").style.display = "none";
      document.getElementById("forgot-password-btn-container").style.display = "flex";
      document.getElementById("forgot-password-warning").style.display = "block";
      setElementAttribute("main-title", "innerHTML", "Forgot Password?");
      setElementAttribute("subtitle", "innerHTML", "Please enter your email address or username to receive an email to reset your password.");
   }
}

function backButtonClicked() {
   document.getElementById("loginForm").style.display = "";
   document.getElementById("manualSSOUsername").style.display = "none";
   document.getElementById("manualForgotPassword").style.display = "none";

   if (isESignatureVerification) {
      document.getElementById("login-btn-container").style.display = "flex";
      document.getElementById("sso-btn-container").style.display = "none";
      document.getElementById("forgot-password-btn-container").style.display = "none";
      document.getElementById("forgot-password-warning").style.display = "none";
      setElementAttribute("main-title", "innerHTML", "Please verify your identity");
      setElementAttribute("subtitle", "innerHTML", "I verify that I am the author of this signature.");
   }
}

function enterSSOUsernameManually() {
   document.getElementById("loginForm").style.display = "none";
   document.getElementById("manualSSOUsername").style.display = "block";
   if (getCookie("ssoUsername")) {
      setElementAttribute("ssoUsername", "value", getCookie("ssoUsername"));
   }
   document.getElementById("ssoUsernameSubmit").addEventListener("click", function () {
      submitSSOUsername();
   });

   if (isESignatureVerification) {
      document.getElementById("login-btn-container").style.display = "none";
      document.getElementById("sso-btn-container").style.display = "flex";
      setElementAttribute("main-title", "innerHTML", "Verify with SSO");
   }
}

function submitSSOUsername() {
   const username = document.getElementById("ssoUsername").value;
   determineSSOProvider(username)
      .then(function (answer) {
         const { ssoUrl, success } = answer.data;
         if (success) {
            if (isESignatureVerification) {
               handleESignatureSSOLogin(ssoUrl);
               return;
            }
            
            window.location.href = ssoUrl;
         }
      })
      .catch(function (e) {
         document.getElementById("ssoLoginErr").style.display = "block";
      });
}

function handleESignatureSSOLogin(ssoUrl) {
   const popup = window.open(ssoUrl, "ssoLogin", "popup=true, width=200, height=200");
   if (!popup) {
      window.alert("Please disable your popup blocker and try again.");
      return;
   }

   return EnvRoutesService.getRoutes().then((routes) => {

      const interval = setInterval(() => {
         const eSignatureVerificationToken = getCookie("eSignatureVerificationToken");
         if (eSignatureVerificationToken) {
               clearInterval(interval);
               popup.close();
               
               verifyESignatureVerificationToken()
               .then((answer) => {
                  delete_cookie("eSignatureVerificationToken", "/");
                  window.sessionStorage.removeItem("loginAttemptNumber");
                  window.parent.postMessage(
                     {
                        success: answer.data.success,
                        user: answer.data.userID,
                     },
                     routes.appUrl
                  );
               });
            }
      }, 1000);
   });
}

document.getElementById("manualSSOUsername").addEventListener(
   "keydown",
   function (event) {
      if (event.key == "Enter") {
         submitSSOUsername();
      }
   },
   false
);

function setCookie(cname, value) {
   var d = new Date();
   d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
   var expires = "expires=" + d.toUTCString();
   if (window.location.hostname.includes("localhost")) {
      delete_cookie(cname, "/", "");
      document.cookie = cname + "=" + value + ";" + expires + ";";
   } else {
      delete_cookie(cname, "/", "limblecmms.com");
      document.cookie = cname + "=" + value + ";" + expires + ";Domain=limblecmms.com";
   }
}
function delete_cookie(name, path, domain) {
   if (getCookie(name)) {
      document.cookie =
         name +
         "=" +
         (path ? ";path=" + path : "") +
         (domain ? ";domain=" + domain : "") +
         ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
   }
}
function getCookie(cname) {
   var name = cname + "=";
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(";");
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
         c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
         return c.substring(name.length, c.length);
      }
   }
   return "";
}

function getCookies(prefixes) {
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(";");
   var found = [];
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
         c = c.substring(1);
      }
      for (var j = 0; j < prefixes.length; j++) {
         if (c.indexOf(prefixes[j]) == 0) {
            var r = c.split("=", 2);
            found[r[0]] = r[1];
            break;
         }
      }
   }
   return found;
}

/**
 * Deletes old cookies that are no longer used.
 */
function deleteUnusedCookies(redirect) {
   var prefixes = [
      'ph_phc', // Pumba / Posthog
   ];

   // temporary until we completely move CDN providers
   if (!/^https:\/\/(?:21cfr-)?app\.limblecmms\.com/.test(redirect)) {
      // clear imperva cookies unless we redirecting to us-prod or 21cfr
      prefixes.push('incap', 'visid_incap');
   }

   const cookies = getCookies(prefixes);
   const domains = calculateCookieDomains();

   for (var domain in domains) {
      for (var key in cookies) {
         delete_cookie(key, "/", domain);
      }
   }
}

/**
 * Calculates all possible cookie domains for the current hostname.
 * @returns {Object} An object with all possible cookie domains as keys.
 */
function calculateCookieDomains() {
   var carry = document.location.hostname;
   var domains = {};
   domains[""] = true;

   while (carry.indexOf('.') != carry.lastIndexOf('.')) {
      carry = carry.split('.').slice(1).join('.');
      domains[carry] = true;
   }

   return domains;
}

function loadLang(lang) {
   if (lang == "english") {
      const en = import("../../i18n/webApp_en.js");
      //load english js file
      en.then(function (en) {
         document.lang = en.webApp_en;
         setFrontEnd();
         setCookie("languageCookie", "english");
      });
      
      //set lang cookie
   } else if (lang == "spanish") {
      const es = import("../../i18n/webApp_es.js");

      es.then(function (es) {
         document.lang = es.webApp_es;
         setCookie("languageCookie", "spanish");
         setFrontEnd();
      });
      // document.lang = es.webApp_es;
   } else if (lang == "thai") {
      const th = import("../../i18n/webApp_th.js");

      th.then(function (th) {
         document.lang = th.webApp_th;
         setCookie("languageCookie", "thai");
         setFrontEnd();
      });
   } else if (lang == "french") {
      const fr = import("../../i18n/webApp_fr.js");

      fr.then(function (fr) {
         document.lang = fr.webApp_fr;
         setCookie("languageCookie", "french");
         setFrontEnd();
      });
   } else if (lang == "portuguese") {
      const pt = import("../../i18n/webApp_pt.js");

      pt.then(function (pt) {
         document.lang = pt.webApp_pt;
         setCookie("languageCookie", "portuguese");
         setFrontEnd();
      });
   } else if (lang == "hebrew") {
      const he = import("../../i18n/webApp_he.js");

      he.then(function (he) {
         document.lang = he.webApp_he;
         setCookie("languageCookie", "hebrew");
         setFrontEnd();
         //right align text
         document.body.classList.add("rightBodyAlignText");
      });
   } else if (lang == "german") {
      const de = import("../../i18n/webApp_de.js");
      de.then(function (de) {
         document.lang = de.webApp_de;
         setCookie("languageCookie", "german");
         setFrontEnd();
      });
   } else if (lang == "chinese") {
      const zh = import("../../i18n/webApp_zh.js");
      zh.then(function (zh) {
         document.lang = zh.webApp_zh;
         setCookie("languageCookie", "chinese");
         setFrontEnd();
      });
   } else if (lang == "Arabic") {
      const ar = import("../../i18n/webApp_ar.js");
      ar.then(function (ar) {
         document.lang = ar.webApp_ar;
         setCookie("languageCookie", "Arabic");
         setFrontEnd();
      });
   } else if (lang == "bulgarian") {
      const bg = import("../../i18n/webApp_bg.js");
      bg.then(function (bg) {
         document.lang = bg.webApp_bg;
         setCookie("languageCookie", "bulgarian");
         setFrontEnd();
      });
   } else if (lang == "farsi") {
      const fa = import("../../i18n/webApp_fa.js");
      fa.then(function (fa) {
         document.lang = fa.webApp_fa;
         setCookie("languageCookie", "farsi");
         setFrontEnd();
      });
   } else if (lang == "italian") {
      const it = import("../../i18n/webApp_it.js");
      it.then(function (it) {
         document.lang = it.webApp_it;
         setCookie("languageCookie", "italian");
         setFrontEnd();
      });
   } else if (lang == "dutch") {
      const nl = import("../../i18n/webApp_nl.js");
      nl.then(function (nl) {
         document.lang = nl.webApp_nl;
         setCookie("languageCookie", "dutch");
         setFrontEnd();
      });
   } else if (lang == "russian") {
      const ru = import("../../i18n/webApp_ru.js");
      ru.then(function (ru) {
         document.lang = ru.webApp_ru;
         setCookie("languageCookie", "russian");
         setFrontEnd();
      });
   } else if (lang == "turkish") {
      const tr = import("../../i18n/webApp_tr.js");
      tr.then(function (tr) {
         document.lang = tr.webApp_tr;
         setCookie("languageCookie", "turkish");
         setFrontEnd();
      });
   } else if (lang == "english/spanish") {
      const zz = import("../../i18n/webApp_zz.js");
      zz.then(function (zz) {
         document.lang = zz.webApp_zz;
         setCookie("languageCookie", "english/spanish");
         setFrontEnd();
      });
   } else if (lang == "training") {
      const tg = import("../../i18n/webApp_tg.js");
      tg.then(function (tg) {
         document.lang = tg.webApp_tg;
         setCookie("languageCookie", "training");
         setFrontEnd();
      });
   } else {
      const en = import("../../i18n/webApp_en.js");
      //load english js file
      en.then(function (en) {
         document.lang = en.webApp_en;
         setFrontEnd();
         setCookie("languageCookie", "english");
      });
   }
}
// function setLang() {
// 	var selectBox = document.getElementById('lang');
// 	var selectedValue = selectBox.options[selectBox.selectedIndex].value;
// 	if (selectedValue == 'english') {
// 		loadLang('english');
// 	} else if (selectedValue == 'spanish') {
// 		loadLang('spanish');
// 	} else if (selectedValue == 'thai') {
// 		loadLang('thai');
// 	} else if (selectedValue == 'french') {
// 		loadLang('french');
// 	} else if (selectedValue == 'portuguese') {
// 		loadLang('portuguese');
// 	} else if (selectedValue == 'hebrew') {
// 		loadLang('hebrew');
// 	}
// }
// document.setLang = setLang;
/*
General Use functions
*/

function checkMobile() {
   if (navigator.userAgent.includes("gonative")) {
      return true;
   }

   if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
   }

   let mobile = new RegExp("m=true").test(window.location.href);
   if (mobile) {
      return true;
   }

   return false;
}
function isAndroid() {
   return /Android/i.test(navigator.userAgent);
}
function evaluateRedirectUrl(responseRedirectUrl) {
   return checkMobile() === true
      ? createMobileRedirectUrl(responseRedirectUrl)
      : responseRedirectUrl;
}
function createMobileRedirectUrl(responseRedirectUrl) {
   const url = new URL(responseRedirectUrl);
   const baseUrl = url.origin;
   const mobileDashboardUrl = baseUrl + MOBILE_REDIRECT_ROUTE;
   return mobileDashboardUrl;
}
function navigateToApp(redirectUrl) {
   if (isAndroid()) {
      window.location.replace(redirectUrl);
   } else {
      window.location.href = redirectUrl;
   }
}
function setLoginLocalStorage(username, password) {
   if (username) {
      localStorage.setItem(userField, btoa(username));
   }
   if (password) {
      localStorage.setItem(passField, btoa(password));
   }

   return true;
}

function getLoginLocalStorage(userField, passField) {
   if (getCookie("email") != null && getCookie("password") != null) {
      setLoginLocalStorage(getCookie("email"), getCookie("password"));
   }

   // console.log(userField);
   let user = localStorage.getItem(userField);
   let pass = localStorage.getItem(passField);

   if (user == null || pass == null) {
      return { user, pass };
   } else {
      return { user: atob(user), pass: atob(pass) };
   }
}

function clearLoginLocalStorage(userField, passField) {
   localStorage.removeItem(userField);
   localStorage.removeItem(passField);

   return true;
}

//Hide signup stuff from IOS because Apple is a pain.
if (navigator.userAgent.includes("iPhone")) {
   document.getElementById("signUpDiv").style.display = "none";
}

const togglePassword = document.querySelector("#togglePassword");
const password = document.querySelector("#password");

togglePassword.addEventListener("click", function (e) {
   // toggle the type attribute
   const type = password.getAttribute("type") === "password" ? "text" : "password";
   password.setAttribute("type", type);
   // toggle the eye slash icon
   this.classList.toggle("fa-eye-slash");
   this.classList.toggle("fa-eye");
});

// ESignatureVerification needs to let the parent know about sso logins
// if (isESignatureVerification) {
//    const params = new Proxy(new URLSearchParams(window.location.search), {
//       get: (searchParams, prop) => searchParams.get(prop),
//    });

//    if (params.error) {
//       window.parent.postMessage(
//          {
//             success: false,
//             user: 0
//          },
//          routes.appUrl
//       );
//       return;
//    }

//    if (params.sso === "true") {
//       const eSignatureVerificationToken = params.eSignatureVerificationToken;
//       const eSignatureVerificationTokenCookie = getCookie("eSignatureVerificationToken");

//       if (eSignatureVerificationToken === eSignatureVerificationTokenCookie) {
//          delete_cookie("eSignatureVerificationToken", "/", "window.location.hostname");
//          window.parent.postMessage(
//             {
//                success: true,
//                user: params.userID
//             },
//             routes.appUrl
//          );
//       }
//    }
// }